import { defineStore } from "pinia";
import { useNotifications } from "./notifications";
import axios from "axios";
import { notify } from "notiwind";

export const useAuth = defineStore("auth-store", {
  state: () => {
    return {
      loading: false,
      loginStatus: Boolean(Number(localStorage.getItem("login_status"))),
      apiToken: localStorage.getItem("api_token"),
      user: {},
      store: {},
    };
  },

  actions: {
    async register(
      storeId,
      name,
      email,
      confirmEmail,
      password,
      confirmPassword
    ) {
      this.loading = true;

      try {
        const response = await axios.post(
          import.meta.env.VITE_API_BASE_URL + "register",
          {
            store_id: storeId,
            name: name,
            email: email,
            email_confirmation: confirmEmail,
            password: password,
            password_confirmation: confirmPassword,
          }
        );

        if (response.data.status) {
          await this.login(email, password);
        }

        this.loading = false;

        return true;
      } catch (error) {
        // console.log("Register error:", error)

        this.loading = false;

        throw error;
      }
    },
    async login(email, password) {
      this.loading = true;

      try {
        const response = await axios.post(
          import.meta.env.VITE_API_BASE_URL + "login",
          { email: email, password: password }
        );

        this.apiToken = response.data.token;
        this.loginStatus = true;

        localStorage.setItem("api_token", this.apiToken);
        localStorage.setItem("login_status", this.loginStatus ? 1 : 0);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.apiToken}`;

        this.loading = false;

        if (import.meta.env.VITE_ENV !== "development") {
          try {
            await this.setupHubSpotChat();
          } catch (error) {
            console.log(
              "Auth - Exception encountered while setting up HubspotChat",
              error
            );
          }
        } else {
          console.log(
            "Auth - Dev mode, ignoring HubspotChat Widget setup.",
            import.meta.env
          );
        }

        try {
          const notificationService = useNotifications();

          // Check available notifications
          notificationService.checkExistingNotifications();

          setInterval(() => {
            notificationService.checkExistingNotifications();
          }, 300000); // 5 min

          try {
            const notificationsResponse = await axios.get("notifications");

            if (
              notificationsResponse &&
              notificationsResponse.data &&
              notificationsResponse.data.status
            ) {
              if (notificationsResponse.data.cant_notifications > 0) {
                notify(
                  {
                    group: "bottom-right",
                    type: "info",
                    title: "Novedades",
                    text: `Tenés ${notificationsResponse.data.cant_notifications} notificaciones pendientes.`,
                  },
                  4000
                ); // 4s
              }
            }
          } catch (error) {
            console.log("Auth - Error obteniendo notificaciones:", error);
          }
        } catch (error) {
          console.log(
            "Auth - Exception encountered while setting up Notifications interval",
            error
          );
        }

        return true;
      } catch (error) {
        // console.log("Login error:", error)

        axios.defaults.headers.common["Authorization"] = null;

        this.loading = false;

        throw error;
      }
    },
    async localLogout() {
      this.loading = true;

      this.loginStatus = false;
      this.apiToken = "";
      this.user = {};

      localStorage.setItem("api_token", null);
      localStorage.setItem("login_status", 0);

      axios.defaults.headers.common["Authorization"] = null;

      this.loading = false;

      return true;
    },
    async logout() {
      this.loading = true;

      try {
        await axios.get(import.meta.env.VITE_API_BASE_URL + "logout");
      } catch (error) {
        // console.log("Logout error:", error);
      }

      this.loginStatus = false;
      this.apiToken = "";
      this.user = {};

      localStorage.setItem("api_token", null);
      localStorage.setItem("login_status", 0);

      axios.defaults.headers.common["Authorization"] = null;

      this.loading = false;

      return true;
    },
    async activateAccount(username) {
      this.loading = true;

      try {
        const response = await axios.post(
          import.meta.env.VITE_API_BASE_URL + "activate_account",
          { email: username }
        );

        this.loading = false;

        return response;
      } catch (error) {
        // console.log("Password Recovery error:", error)

        this.loading = false;

        throw error;
      }
    },
    async passwordRecovery(username) {
      this.loading = true;

      try {
        const response = await axios.post(
          import.meta.env.VITE_API_BASE_URL + "password_recovery",
          { email: username }
        );

        this.loading = false;

        return response;
      } catch (error) {
        // console.log("Password Recovery error:", error)

        this.loading = false;

        throw error;
      }
    },
    async resetPassword(username, newPassword, signature) {
      this.loading = true;

      try {
        const response = await axios.post(
          import.meta.env.VITE_API_BASE_URL + "reset_password/" + username,
          {
            new_password: newPassword,
          },
          {
            params: {
              signature: signature,
            },
          }
        );

        this.loading = false;

        return response;
      } catch (error) {
        // console.log("Reset Password error:", error)

        this.loading = false;

        throw error;
      }
    },

    async setStore(store) {
      this.store = store;
    },

    async setupHubSpotChat() {
      console.log("Fetching HubspotChat Widget configuration");
      /*
        configure window.hsConversationsSettings if needed.
      */
      const hubspotTokenResponse = await axios.get(
        "hubspot/current/identification"
      );
      if (hubspotTokenResponse.data.status) {
        window.hsConversationsSettings = {
          identificationEmail: hubspotTokenResponse.data.identification_email,
          identificationToken: hubspotTokenResponse.data.identification_token,
        };
      }

      const onConversationsAPIReady = () => {
        console.log("Restarting HubspotChat Widget");
        window.HubSpotConversations.resetAndReloadWidget();
      };

      if (window.HubSpotConversations) {
        /*
          If external API methods are already available, use them.
        */
        onConversationsAPIReady();
      } else {
        /*
          Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
          These callbacks will be called once the external API has been initialized.
        */
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }
    },
  },
});
