<template>
  <div
    class="sidebar-bottom flex w-full flex-col items-center place-self-end p-1 md:mt-auto"
  >
    <!-- <hr class="my-2 w-full border border-neutral" /> -->
    <!-- <div
      v-if="layoutIsSidebarOpen"
      class="sidebar-bottom-content whitespace-nowrap"
    >
      sidebar bottom content
    </div>
    <div v-else>
      <icon-component
        name="Bell"
        type="solid"
        classes="h-6 w-5"
      />
    </div> -->
  </div>
</template>

<script setup>
import { toRefs } from "vue";

// Props
const props = defineProps(["layoutIsSidebarOpen"]);

// Variables
const { layoutIsSidebarOpen } = toRefs(props);
</script>
