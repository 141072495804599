<template>
  <div
    class="sidebar-content mt-auto flex w-full flex-col-reverse py-1 pt-8 pb-0 md:mt-0 md:flex-col"
  >
    <div
      v-if="layoutIsSidebarOpen"
      class="sidebar-content-content flex w-full flex-col-reverse md:flex-col"
    >
      <sidebar-item
        v-for="(m, index) in menus"
        :mini="false"
        :name="m.name"
        :href="m.href"
        :icon="m.icon"
        :type="m.type"
        :children="m.children"
        :showSeparatorTopLine="index !== 0"
      />
    </div>
    <template v-else>
      <sidebar-item
        v-for="(m, index) in menus"
        :mini="true"
        :name="m.name"
        :href="m.href"
        :icon="m.icon"
        :type="m.type"
        :children="m.children"
        :showSeparatorTopLine="index !== 0"
      />
    </template>
  </div>
</template>

<script setup>
import SidebarItem from "./SidebarItem.vue";
import { onMounted, ref, toRefs } from "vue";
import axios from "axios";
import { useLogger } from "@/stores/logger";

const logger = useLogger();

// Props
const props = defineProps(["layoutIsSidebarOpen"]);

// Variables
const { layoutIsSidebarOpen } = toRefs(props);

const store = ref({});
const loading = ref(true);
const menus = ref([
  { name: "Dashboard", href: "/dashboard", type: "link", icon: "ChartPie" },
  { name: "Ordenes", href: "/orders", type: "link", icon: "CurrencyDollar" },
  { name: "Productos", href: "/products", type: "link", icon: "Cube" },
  { name: "Clientes", href: "/customers", type: "link", icon: "Users" },
  { name: "Categorias de cliente", href: "/customer-categories", type: "link", icon: "TableCells" },
  {
    name: "Reglas de Negocio",
    href: "/business-rules",
    type: "link",
    icon: "Briefcase",
  },
  { name: "Cupones", href: "/coupons", type: "link", icon: "Ticket" },
  // { name: "Reportes", href: "/reports", type: "link", icon: "ChartBar" },
  {
    name: "Tools",
    href: "/tools",
    type: "link",
    icon: "WrenchScrewdriver",
  },
  // {
  //   name: "Configuración",
  //   href: "/configuration",
  //   type: "link",
  //   icon: "AdjustmentsVertical",
  // },
  {
    name: "Configuración",
    type: "dropdown",
    href: "/configuration",
    icon: "AdjustmentsVertical",
    children: [{
      name: "Estados Personalizados",
      href: "/custom-status",
      type: "link",
      icon: "Square3Stack3D",
    }]
  },
]);

// Methods
const getSidebarContent = async () => {
  loading.value = true;

  const response = await axios.get("menus");
  // console.log('menus response', response);

  menus.value = response.data;

  loading.value = false;
};

const getStore = async () => {
  try {
    const response = await axios.get("stores/current", {
      params: {
        include_apps: true,
        include_plan: true,
        include_subscription: true,
      },
    });
    if (response && response.data && response.data.status) {
      // console.log("Current Store:", response.data);
      store.value = response.data.store;
    }
  } catch (error) {
    logger.log("Error obteniendo current Store:", error);
  }
}

const isLocationsPrioritizationInstalled = () => {
  if (
    store.value &&
    store.value.applications &&
    store.value.applications.some((a) => a.name === "location-prioritization")
  ) {
    return true;
  }

  return false;
};
const isLocationsPrioritizationSubscribed = () => {
  if (
    store.value.enforce_subscription &&
    !(
      store.value &&
      store.value.active_apps &&
      store.value.active_apps["location-prioritization"] &&
      store.value.active_apps["location-prioritization"].status
    ) &&
    !(
      store.value &&
      store.value.free_trial_apps &&
      store.value.free_trial_apps["location-prioritization"] &&
      store.value.free_trial_apps["location-prioritization"].status
    )
  ) {
    return false;
  }

  return true;
};

// Lifecycle hooks
onMounted(async () => {
  // TODO: obtener menus del sidebar desde API
  // getSidebarContent(); // TODO: implementar a futuro

  await getStore();

  if (isLocationsPrioritizationInstalled() && isLocationsPrioritizationSubscribed()) {
    menus.value.splice(
      menus.value.indexOf((m) => m.href === '/coupons') - 1,
      0,
      { name: "Depositos", href: "/locations", type: "link", icon: "Truck" },
    )
  }
});
</script>
