<template>
  <notification-component />
  <VOnboardingWrapper
    v-if="loginStatus"
    ref="wrapper"
    :steps="steps"
    class="z-50"
  />
  <div class="hidden lg:flex">
    <!-- Este div de aca arriba podria tener estos eventos de swipe -->
    <!-- v-touch:swipe.left="swipeLeftHandler" -->
    <!-- v-touch:swipe.right="swipeRightHandler" -->
    <notification-sidebar
      v-if="loginStatus && layoutSidebarNotificationsOpen"
    />
    <sidebar-component v-if="loginStatus && isSidebarVisible" />
    <div
      class="w-full flex-col flex-wrap"
      :class="
        loginStatus && isSidebarOpen
          ? 'lg:max-w-[calc(100%-224px)] xl:max-w-[calc(100%-224px)]'
          : 'max-w-[calc(100%-48px)]'
      "
    >
      <!-- <div v-if="(storeDisabled && route.path !== '/tools')">
        <header-component v-if="isHeaderVisible" />
        <DisabledStorePage />
      </div> -->
      <!-- <router-view v-else /> -->
      <router-view />
    </div>
  </div>
  <div class="flex lg:hidden"><under-construction-mobile /></div>
  <modal-component />
  <reload-prompt />
</template>

<script setup>
import { computed, nextTick, onMounted, ref /* , watch */ } from "vue";
import { useAuth } from "./stores/auth";
import { useLayout } from "./stores/layout";
import { useNotifications } from "./stores/notifications";
import SidebarComponent from "./components/layout/sidebar/SidebarComponent.vue";
import HeaderComponent from "@/components/layout/header/HeaderComponent.vue";
import ModalComponent from "./components/layout/modal/ModalComponent.vue";
import ReloadPrompt from "./components/prompts/ReloadPrompt.vue";
import UnderConstructionMobile from "./components/errors/UnderConstructionMobile.vue";
import { VOnboardingWrapper } from "v-onboarding";
import NotificationComponent from "./components/notifications/NotificationComponent.vue";
import NotificationSidebar from "./components/notifications/NotificationSidebar.vue";
import axios from "axios";
import DisabledStorePage from "./components/errors/DisabledStorePage.vue";
import { useRoute } from "vue-router";

// Variables
const auth = useAuth();
const route = useRoute();
const layout = useLayout();
const notificationService = useNotifications();
const storeDisabled = ref(false);

// Variables
// - Onboarding
const wrapper = ref();
const steps = [
  {
    attachTo: {
      element: "h2.step-1",
    },
    content: {
      title: "🥳 ¡El equipo de LerenTools te da la bienvenida! 🎉 ✨",
      description:
        "En este panel vas a poder gestionar todas las aplicaciones que instales en tu tienda",
    },
    options: {
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [100, 5],
            },
          },
        ],
      },
    },
  },
  {
    attachTo: {
      element: "div.step-2",
    },
    content: {
      title: "Importante 📣",
      description:
        "Haciendo click en este boton vas a poder gestionar las preferencias de tu cuenta y cerrar tu sesión si así lo deseas.",
    },
    options: {
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 5],
            },
          },
        ],
      },
    },
  },
];
// TODO: Deberiamos llevar un registro de qué steps vio el cliente para no empezar siempre del 1
// TODO: Deberiamos registrar cuando el cliente llega al 'Finish' del ultimo paso asi no volvemos a mostrarle el on boarding

// Computed
const loginStatus = computed(() => auth.loginStatus);
const isSidebarVisible = computed(() => layout.isSidebarVisible);
const isSidebarOpen = computed(() => layout.isSidebarOpen);
const onboardingStatus = computed(() => layout.onboardingStatus);
const layoutSidebarNotificationsOpen = computed(
  () => layout.isSidebarNotificationsOpen
);
const isHeaderVisible = computed(() => layout.isHeaderVisible);

// Methods
const startOnBoarding = async () => {
  // TODO: await get onBoardingStatus(auth.user.id) o algo asi para saber si tenemos que mostrar el on boarding o no
  await nextTick(); // Waiting for DOM to render child components

  setTimeout(() => {
    if (wrapper.value && loginStatus.value) {
      wrapper.value.start();
      // wrapper.value.goToStep(2)
      // wrapper.value.finish()

      // Si ya no necesitamos el watcher
      // loginStatusWatcherStop()
    }
  }, 1000);
};

const swipeLeftHandler = () => {
  layout.closeSidebar();
};

const swipeRightHandler = () => {
  layout.openSidebar();
};

// Watchers
/* const loginStatusWatcherStop =  */ // watch(onboardingStatus, startOnBoarding); // TODO: descomentar cuando necesitemos onbarding

// Lifecycle hooks
onMounted(async () => {
  // Get current theme
  const theme = localStorage.getItem("theme");
  if (!theme) {
    localStorage.setItem("theme", "dark");
  } else {
    layout.setCurrentTheme(theme);
  }

  // startOnBoarding(); // TODO: ¿uncomment?
  if (loginStatus.value) {
    const storeResponse = await axios.get("stores/current", {
      params: {
        include_apps: true,
        include_plan: true,
        include_subscription: true,
      },
    });
    if (storeResponse.data.status) {
      // Get current lang
      const lang = localStorage.getItem("lang");
      if (!lang) {
        localStorage.setItem("lang", storeResponse.data.store.main_language);
        layout.setCurrentLang(storeResponse.data.store.main_language);
      } else {
        layout.setCurrentLang(lang);
      }
      auth.setStore(storeResponse.data.store);
      layout.setStoreName(storeResponse.data.store.names[lang]);
      storeDisabled.value = storeResponse.data.store.status == 'Disabled'
    }
    if (import.meta.env.VITE_ENV !== "development") {
      await auth.setupHubSpotChat();
    } else {
      console.log("App - Dev mode, ignoring HubspotChat Widget setup.");
    }

    // Check available notifications
    notificationService.checkExistingNotifications();

    setInterval(() => {
      notificationService.checkExistingNotifications();
    }, 300000); // 5 min
  }
});
</script>
